<template>
  <div
    class="content-item-media content-item-media--clickable"
    @click="$emit('view-content', contentItem)"
  >
    <div class="content-item-media__picture">
      <img
        v-if="hasImgCover"
        class="picture"
        :src="contentItem.coverImage"
        :alt="contentItem.name"
      />

      <div v-if="!hasImgCover && isContentTypeVideo" class="picture__no-cover">
        <v-icon class="no-cover__icon"> mdi-play-circle </v-icon>
      </div>
    </div>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <div class="content-item-media__header" v-bind="attrs" v-on="on">
          {{ contentItem.name }} <span v-html="contentItem.name" />
        </div>
      </template>

      <span>{{ contentItem.name }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'ContentItemMedia',
  props: {
    contentItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasImgCover() {
      return !!this.contentItem.coverImage;
    },
    isContentTypeVideo() {
      return this.contentItem.content[0].type === 'video';
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/styles/core/variables';

.content-item-media {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  padding: 1rem;
  width: 100%;

  &--clickable {
    cursor: pointer;
  }

  &__header {
    width: 100%;
    min-height: 20px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    color: var(--v-lightFont-base);
  }

  &__picture {
    width: 100%;
    margin-bottom: 10px;
  }

  .picture {
    width: 100%;
    aspect-ratio: 16/9;
  }
}

.icon {
  color: var(--v-primary-base);
}

.picture {
  &__no-cover {
    padding: 15px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    aspect-ratio: 16/9;
  }
}

.no-cover {
  &__icon {
    color: var(--v-gray-base);
    font-size: 60px;
  }
}
</style>
