<template>
  <div v-if="event && contentCategories">
    <div id="content-library" :style="{ 'background-image': 'url(' + backgroundImage + ')' }">
      <logo class="hidden-lg-and-up" :logo="event.logoMobile" />

      <v-container class="content-library px-0 py-0" fluid>
        <v-row>
          <v-col cols="12" lg="2">
            <logo class="hidden-md-and-down" type="round" :logo="event.logoWeb" />
          </v-col>

          <v-col cols="12" lg="8" class="content-library__content">
            <h2 class="content-library__title">{{ libraryName }}</h2>

            <v-sheet dark class="categories">
              <div
                class="category"
                v-for="contentCategory in contentCategories"
                :key="contentCategory.id"
                @click="openCategory(contentCategory)"
              >
                <div class="category__picture-wrapper">
                  <img class="category__picture" :src="contentCategory.coverImage" />
                </div>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="category__title" v-bind="attrs" v-on="on">
                      {{ contentCategory.name }}
                    </div>
                  </template>

                  <span>{{ contentCategory.name }}</span>
                </v-tooltip>
              </div>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Logo from '@/components/logo/Logo.vue';
import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';
import { APP_CONTENT_MODULE } from '@/stores/umanize-app/actions/content/app-content.actions';
import { APP_CUSTOM_UI_MODULE } from '@/stores/umanize-app/actions/custom-ui/app-custom-ui.actions';

export default {
  name: 'ContentLibrary',
  components: {
    Logo,
  },
  computed: {
    ...mapGetters(APP_CONTENT_MODULE, ['contentCategories']),
    ...mapGetters(APP_EVENT_MODULE, ['event']),
    ...mapGetters(APP_CUSTOM_UI_MODULE, ['customUi']),
    backgroundImage() {
      return this.event.options?.contentLibraryOptions?.backgroundImageUrl;
    },
    libraryName() {
      return this.customUi?.contentLibraryBtnText || this.$t('lobby.content');
    },
  },
  methods: {
    openCategory(contentCategory) {
      this.$router.push({
        name: 'ContentCategory',
        params: {
          eventId: this.$route.params.eventId,
          categoryId: contentCategory.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

#content-library {
  min-height: calc(100vh - 6rem);
  background-image: url('~@assets/backgrounds/poster.jpg');
  background-position: bottom center;
  background-size: cover;
  background-attachment: fixed;
}

.content-library {
  height: 100%;

  &__title {
    padding: 1rem 0 0;

    color: #ffffff;
  }

  &__content {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 25px;
    min-height: calc(100vh - 6rem);
  }
}

.categories {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  margin: 0 -1rem;
}

.category {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;

  padding: 1rem;
  cursor: pointer;

  &__picture-wrapper {
    width: 100%;
    aspect-ratio: 16/9;
  }

  &__picture {
    width: 100%;
    aspect-ratio: 16/9;
  }

  &__title {
    width: 100%;
    min-height: 20px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    color: var(--v-lightFont-base);
  }
}

@include breakpoint(small) {
  .categories {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: inherit;
  }

  .category {
    width: calc(100% / 3);
  }
}

@include breakpoint(medium) {
  .category {
    width: calc(100% / 4);
  }
}
</style>
