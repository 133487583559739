<template>
  <div v-if="contentItem && event">
    <v-sheet
      dark
      v-if="contentItem"
      id="content"
      :style="{ 'background-image': 'url(' + backgroundImage + ')' }"
    >
      <logo class="hidden-lg-and-up" :logo="event.logoMobile" />

      <v-container class="content px-0 py-0" fluid>
        <v-row>
          <v-col class="logos" cols="12" lg="2">
            <logo class="hidden-md-and-down" type="round" :logo="event.logoWeb" />
          </v-col>

          <v-col cols="12" lg="8" class="content__content">
            <div class="back">
              <v-btn @click="goBack" class="back__button" text>
                <v-icon left>mdi-arrow-left</v-icon>
                {{ backLabel }}
              </v-btn>
            </div>

            <div
              class="display"
              v-if="contentItem && selectedContent"
              :class="{ document: !isContentType(contentItem, 'video') }"
            >
              <div v-if="!hasOneOrLessContent" class="display__buttons">
                <v-btn
                  class="button"
                  v-for="item in contentItem.content"
                  :key="item.id"
                  color="primary"
                  @click="changeSelectedContent(item.type)"
                  :outlined="selectedContent.type !== item.type"
                >
                  {{ item.name }}
                </v-btn>
              </div>

              <div class="display__content">
                <div
                  class="content"
                  :class="{ document__content: !isContentType(contentItem, 'video') }"
                >
                  <h2>{{ contentItem.name }}</h2>

                  <div
                    class="carousel"
                    :class="{ document__carousel: !isContentType(contentItem, 'video') }"
                  >
                    <div class="carousel__counter" v-if="hasMoreThanOneUrl">
                      {{ counter }}
                    </div>

                    <v-carousel
                      v-model="carouselCounter"
                      height="auto"
                      :show-arrows="hasMoreThanOneUrl"
                      :hide-delimiters="hasNoOrOneUrl"
                    >
                      <v-carousel-item v-for="(data, index) in selectedContent.url" :key="index">
                        <img
                          v-if="isContentType(contentItem, 'image')"
                          class="picture"
                          :src="data"
                        />

                        <vimeo-video v-if="isContentType(contentItem, 'video')" :videoUrl="data" />
                      </v-carousel-item>
                    </v-carousel>
                  </div>
                </div>

                <div class="display-content__description" v-html="contentItem.description" />

                <images-carousel class="display-content__carousel" :items="contentItems" />
              </div>
            </div>
          </v-col>
          <v-col cols="12" lg="2" />
        </v-row>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import VimeoVideo from '@/components/video/Video.vue';
import Logo from '@/components/logo/Logo.vue';
import ImagesCarousel from '@/components/images-carousel/ImagesCarousel.vue';

import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';
import { APP_CONTENT_MODULE } from '@/stores/umanize-app/actions/content/app-content.actions';
import {
  GET_CONTENT_CATEGORY,
  GET_CONTENT_CATEGORIES,
  GET_CONTENT_ITEM,
  GET_CONTENT_ITEMS,
} from '@/stores/agnostic/actions/content/agnostic-content.actions';

export default {
  name: 'ContentItem',
  data: () => ({
    tab: null,
    selectedContent: null,
    carouselCounter: 0,
  }),
  components: {
    Logo,
    ImagesCarousel,
    VimeoVideo,
  },
  computed: {
    ...mapGetters(APP_CONTENT_MODULE, [
      'contentItems',
      'contentItem',
      'contentCategory',
      'contentCategories',
    ]),
    ...mapGetters(APP_EVENT_MODULE, ['event']),
    counter() {
      return this.$t('content.counter', {
        counter: this.carouselCounter + 1,
        length: this.selectedContent.url.length,
      });
    },
    hasOneOrLessContent() {
      return this.contentItem?.content.length <= 1;
    },
    hasMoreThanOneUrl() {
      return this.selectedContent.url.length > 1;
    },
    hasNoOrOneUrl() {
      return this.selectedContent.url.length <= 1;
    },
    backgroundImage() {
      return this.event.options?.contentLibraryOptions?.backgroundImageUrl;
    },
    backLabel() {
      return this.contentCategories.length <= 1
        ? this.$t('content.back.VOD')
        : this.$t('content.back.category', { category: this.contentCategory.name });
    },
  },
  methods: {
    ...mapActions(APP_CONTENT_MODULE, [
      GET_CONTENT_ITEMS,
      GET_CONTENT_ITEM,
      GET_CONTENT_CATEGORY,
      GET_CONTENT_CATEGORIES,
    ]),
    async initialize() {
      const { eventId, categoryId, contentItemId } = this.$route.params;
      await this[GET_CONTENT_ITEMS]({
        eventId,
        categoryId,
      });

      await this[GET_CONTENT_ITEM]({
        eventId,
        categoryId,
        contentItemId,
      });

      if (this.contentItem?.content?.length) {
        this.selectedContent = {
          ...this.contentItem.content[0],
        };
      }

      await this[GET_CONTENT_CATEGORY]({
        eventId,
        categoryId,
      });

      await this[GET_CONTENT_CATEGORIES](eventId);
    },
    goBack() {
      const { eventId, categoryId } = this.$route.params;

      this.$router.push({
        name: 'ContentCategory',
        params: {
          eventId,
          categoryId,
        },
      });
    },
    changeSelectedContent(type) {
      this.selectedContent = this.contentItem.content.find((content) => content.type === type);
    },
    isContentType(item, type) {
      return this.selectedContent.type === type;
    },
  },
  mounted() {
    this.initialize();
  },
  watch: {
    $route() {
      this.initialize();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

#content {
  min-height: calc(100vh - 6rem);
  background-image: url('~@assets/backgrounds/cinema.jpg');
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
}

.content {
  height: 100%;

  &__content {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 25px;
  }
}

.carousel {
  margin: 1rem 0;
  position: relative;

  &__counter {
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: var(--v-primary-base);
    font-weight: $bold;

    z-index: 10;
  }
}

.back {
  padding: 1rem;
}

.document {
  height: 100%;
  max-height: 80vh;

  &__carousel {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }
}

.display {
  display: flex;
  flex-direction: column;

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;

    width: 100%;
  }

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 1rem 1rem;
  }
}

.button {
  margin: 0.5rem 0.5rem;
}

.picture {
  width: 100%;
}

.display-content {
  &__description {
    margin-bottom: 1.25rem;
  }

  &__carousel {
    width: 100%;
    margin: 0;
  }
}

@include breakpoint(medium) {
  .logos {
    min-height: calc(100vh - 6rem);
  }

  .display {
    flex-direction: row;

    &__buttons {
      flex-direction: column;
      justify-content: flex-start;

      width: 15%;
    }
  }

  .button {
    margin: 0.5rem 0;
  }
}
</style>
