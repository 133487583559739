<template>
  <div
    :class="['content-item-extern', { small: !hasCoverImage }]"
    data-test-id="content-item-extern"
    @click="open"
  >
    <div class="content-item-extern__content">
      <img
        v-if="hasCoverImage"
        class="content__picture"
        :src="contentItem.coverImage"
        :alt="contentItem.name"
      />

      <template v-else>
        <v-icon v-if="isLink" class="content__icon">mdi-web</v-icon>
        <v-icon v-else class="content__icon">mdi-file</v-icon>
      </template>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div class="content__header" v-bind="attrs" v-on="on">
            <span v-html="contentItem.name" />
          </div>
        </template>

        <span v-html="contentItem.name" />
      </v-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentItemExtern',
  props: {
    contentItem: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
      validator(value) {
        return ['file', 'link'].includes(value);
      },
    },
  },
  computed: {
    isLink() {
      return this.type === 'link';
    },
    hasCoverImage() {
      return !!this.contentItem.coverImage;
    },
  },
  methods: {
    open() {
      window.open(this.contentItem.content[0].url[0], '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/styles/core/variables';

.content-item-extern {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;

  padding: 1rem;
  width: 100%;

  text-decoration: none;
  color: white;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

.content {
  &__icon {
    font-size: 50px;
    margin-bottom: 5px;
  }

  &__header {
    width: 100%;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    color: var(--v-lightFont-base);
  }

  &__picture {
    width: 100%;
    aspect-ratio: 16/9;
    margin-bottom: 10px;
  }
}

.small {
  margin-right: 40px;
  width: auto !important;
}
</style>
