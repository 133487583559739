<template>
  <div v-if="event && contentItems">
    <v-sheet
      dark
      id="content-category"
      :style="{ 'background-image': 'url(' + backgroundImage + ')' }"
    >
      <logo class="hidden-lg-and-up" :logo="event.logoMobile" />

      <v-container class="content-category px-0 py-0" fluid>
        <v-row>
          <v-col cols="12" lg="2">
            <logo class="hidden-md-and-down" type="round" :logo="event.logoWeb" />
          </v-col>

          <v-col cols="12" lg="8" class="content-category__content">
            <div class="back">
              <v-btn :to="contentRoute" class="back__button" text>
                <v-icon left>mdi-arrow-left</v-icon>
                {{ $t('content.back.VOD', { name: libraryName }) }}
              </v-btn>
            </div>

            <h2 class="content-category__title">
              {{ contentCategoryName }}
            </h2>

            <div v-if="!!filteredContent('video').length">
              <v-divider class="divider" />

              <p class="content-category__subtitle">{{ $t('content.medias') }}</p>

              <div class="items">
                <content-item-media
                  class="item"
                  v-for="contentItem in filteredContent('video')"
                  :key="contentItem.id"
                  :contentItem="contentItem"
                  @view-content="viewContent"
                  @add-to-agenda="addToAgenda"
                />
              </div>
            </div>

            <div v-if="!!filteredContent('file').length">
              <v-divider class="divider" />

              <p class="content-category__subtitle">{{ $t('content.files') }}</p>

              <div class="items">
                <content-item-extern
                  class="item"
                  v-for="contentItem in filteredContent('file')"
                  :key="contentItem.id"
                  :contentItem="contentItem"
                  type="file"
                />
              </div>
            </div>

            <div v-if="!!filteredContent('link').length">
              <v-divider class="divider" />

              <p class="content-category__subtitle">{{ $t('content.links') }}</p>
              <div class="items">
                <content-item-extern
                  class="item"
                  v-for="contentItem in filteredContent('link')"
                  :key="contentItem.id"
                  :contentItem="contentItem"
                  type="link"
                />
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import ContentItemMedia from '@/components/content-item-media/ContentItemMedia.vue';
import ContentItemExtern from '@/components/content-item-extern/ContentItemExtern.vue';
import Logo from '@/components/logo/Logo.vue';

import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';
import { APP_CONTENT_MODULE } from '@/stores/umanize-app/actions/content/app-content.actions';
import {
  GET_CONTENT_CATEGORY,
  GET_CONTENT_ITEMS,
  GET_CONTENT_CATEGORIES,
} from '@/stores/agnostic/actions/content/agnostic-content.actions';
import { APP_CUSTOM_UI_MODULE } from '@/stores/umanize-app/actions/custom-ui/app-custom-ui.actions';

export default {
  name: 'ContentCategory',
  components: {
    ContentItemMedia,
    ContentItemExtern,
    Logo,
  },
  computed: {
    ...mapGetters(APP_CONTENT_MODULE, ['contentItems', 'contentCategories', 'contentCategory']),
    ...mapGetters(APP_EVENT_MODULE, ['event']),
    ...mapGetters(APP_CUSTOM_UI_MODULE, ['customUi']),
    backgroundImage() {
      return this.event.options?.contentLibraryOptions?.backgroundImageUrl;
    },
    contentRoute() {
      return `/events/${this.event.id}/content-library`;
    },
    contentCategoryName() {
      return this.contentCategory?.name;
    },
    libraryName() {
      return this.customUi?.contentLibraryBtnText || this.$t('lobby.content');
    },
  },
  methods: {
    ...mapActions(APP_CONTENT_MODULE, [
      GET_CONTENT_CATEGORY,
      GET_CONTENT_ITEMS,
      GET_CONTENT_CATEGORIES,
    ]),
    viewContent(contentItem) {
      this.$router.push({
        name: 'Content',
        params: {
          eventId: this.$route.params.eventId,
          categoryId: this.$route.params.categoryId,
          contentItemId: contentItem.id,
        },
      });
    },
    addToAgenda(contentItem) {
      console.log(contentItem);
    },
    filteredContent(type) {
      return this.contentItems.filter((cc) => cc.content[0]?.type === type);
    },
    initialize() {
      const { eventId, categoryId } = this.$route.params;

      this[GET_CONTENT_CATEGORY]({ eventId, categoryId });
      this[GET_CONTENT_ITEMS]({ eventId, categoryId });
    },
  },
  mounted() {
    this.initialize();
  },
  watch: {
    $route() {
      this.initialize();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/core/variables';

#content-category {
  min-height: calc(100vh - 6rem);
  background-image: url('~@assets/backgrounds/poster.jpg');
  background-position: bottom center;
  background-size: cover;
  background-attachment: fixed;
}

.content-category {
  height: 100%;

  &__title {
    padding: 1rem 0 0;

    color: #ffffff;
  }

  &__subtitle {
    font-size: $large-font-size;
    font-weight: $bold;
  }

  &__content {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 25px;
  }
}

.back {
  &__button {
    &:hover {
      opacity: 0.6;
    }
  }
}

.items {
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: transparent;
  margin: 0 -1rem;
}

.theme--dark.v-btn--active::before {
  opacity: 0;
}

.divider {
  margin: 10px 0 15px;
  border-color: rgba(255, 255, 255, 0.3);
}

@include breakpoint(small) {
  .items {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: inherit;
  }

  .item {
    width: calc(100% / 3);
  }
}

@include breakpoint(medium) {
  .item {
    width: calc(100% / 4);
  }
}
</style>
